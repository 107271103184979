var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card-body',{staticClass:"text-center table-responsive"},[_c('b-table',{attrs:{"items":_vm.data,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(reports)",fn:function(row){return [_c('b-nav',{attrs:{"card-header":"","pills":""}},[(row.item.reports !=='')?[(Array.isArray(row.item.reports.reports_entry))?_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","text":"Report"}},_vm._l((row.item.reports.reports_entry),function(reports,index){return _c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'),expression:"reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'"}],key:index,attrs:{"to":{
                name: 'reports',
                query: { url: reports.report_url, id: _vm.id, code: row.item.code, fTour: true, title: reports.report_title  }
              },"title":reports.report_title}},[_vm._v(_vm._s(reports.report_title))])}),1):_c('b-dropdown',{attrs:{"id":"dropdown-dropleft","dropleft":"","text":"Report"}},_vm._l((row.item.reports),function(reports,index){return _c('b-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'),expression:"reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'"}],key:index,attrs:{"to":{
                name: 'reports',
                query: { url: reports.report_url, id: _vm.id, code: row.item.code, fTour: true, title: reports.report_title  }
              },"title":reports.report_title}},[_vm._v(_vm._s(reports.report_title))])}),1)]:_vm._e()],2)]}},{key:"cell(full_name)",fn:function(row){return [_c('b-nav-item',{class:{major : row.item.major == 'Y'},attrs:{"to":'/tournament/' + row.item.code + '/' + _vm.id}},[_vm._v(" "+_vm._s(row.item.full_name)+" ")])]}},{key:"cell(start_date)",fn:function(row){return [(_vm.code == row.item.code)?_c('div',{staticClass:"activeTournament"}):_vm._e(),_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.start_date)+" ")])]}},{key:"cell(course)",fn:function(row){return [_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.course)+" ")])]}},{key:"cell(total_prize_fund)",fn:function(row){return [_c('span',{class:{major : row.item.major == 'Y'}},[_vm._v(" "+_vm._s(row.item.total_prize_fund)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }