<template>
  <div>
    <div class="grey">
      <Slider :Year="tm_params.tm_params.season_code" :config="config" :DEFIMAGESAMS3="DEFIMAGESAMS3" />
    </div>
    <b-card title="Card Title" no-body class="pad">
      <b-card-header header-tag="nav">
        <b-row>
          <b-col>
            <h2 class="FullScheduleTitle">
              Full Schedule
            </h2>
          </b-col>
          <b-col class="hideOnmob">
            <b-nav card-header pills>
              <b-dropdown :text="this.id + ' Season'" class="dropDown">
                <b-dropdown-item
                  v-on:click="changeYear"
                  v-for="(seasons,
                  index) in tm_params.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :title="seasons.code"
                  :year="seasons.code"
                  >{{ seasons.desc }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="hideOnDesk">
            <b-nav card-header pills>
              <b-dropdown :text="this.id + ' Season'">
                <b-dropdown-item
                  v-on:click="changeYear"
                  v-for="(seasons,
                  index) in tm_params.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :title="seasons.code"
                  :year="seasons.code"
                  >{{ seasons.desc }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </b-col>
        </b-row>
      </b-card-header>
      <ScheduleTable
        :config="config"
        :fields="fields"
        :id="id"
        :data="data"
        :code="tm_params.code"
      />
    </b-card>
    <div>
      <Footer :white="white" :config="config" />
    </div>
  </div>
</template>

<script>
import ScheduleTable from "@/components/scheduleTable.vue";
import apiCall from "@/services/api.js";
import Slider from "@/components/slider.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "schedule",
  props: ["config"],
  components: {
    ScheduleTable,
    Slider,
    Footer,
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],
      white: false,
      fields: [
        { key: "start_date", label: "Dates", class: "scheduleNav" },
        { key: "full_name", label: "Name", class: "scheduleNav nameSchedule" },
        { key: "course", label: "Venue", class: "scheduleNav venueOption" },
        { key: "total_prize_fund", label: "Prize Fund", class: "scheduleNav" },
        { key: "reports", label: "Reports", class: "scheduleNav" },
      ],
    };
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
        apiCall
          .tmParamsYear(id)
          .then(({ data }) => {
            this.data = data.tournaments.tournaments_entry;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.id = first_call.data.tm_params.season_code;
      this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmParamsYear(this.id);
      this.data = second_call.data.tournaments.tournaments_entry;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
::v-deep .table-responsive {
  overflow-x: unset!important;
}
::v-deep .dropdown-menu {
  width: 300px;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
::v-deep .dropDown {
  width: 300px!important;
}
::v-deep .carousel-item {
  height: 450px;
}
::v-deep td.nameSchedule > li > a:hover {
  text-decoration: underline;
}
.hideOnDesk > .card-header-pills {
  float: left;
  padding-right: 5%;
  padding-left: 13px;
  margin-top: 2em;
  width: 100%;
}
::v-deep button {
  color: white;
  background-color: #183167;
}
::v-deep button:hover {
  color: #fff;
  background-color: #668CE0;
}
::v-deep .dropdown.show > .btn {
  color: #fff;
  background-color: #668CE0;
}
::v-deep th {
  color: white;
  background-color: #668CE0;
}
::v-deep tr:nth-child(even) {
  background-color: #f2f2f2;
}
.card-header-pills {
  float: right;
  padding-right: 2%;
}
#sliderTitle {
  padding: 5%;
  /* padding-left: 313px; */
  font-weight: 400;
  padding-left: 0;
  margin-left: 70px;
}
h2.FullScheduleTitle {
  font-weight: 700;
}
.card-header {
  background-color: white;
  border-bottom: white;
  margin-top: 3em;
  margin-bottom: 2em;
}
.card {
  border: white;
}
.grey {
  background-color: #f0f0f0;
  /* margin-top: -2%; */
  /* padding-top: 3em; */
  padding-top: 1em;
}
::v-deep th.scheduleNav {
  font-size: 10pt;
  text-align: left;
}
::v-deep td.scheduleNav {
  text-align: left;
  font-size: 1rem;
  vertical-align: middle;
}
::v-deep th.nameSchedule {
  padding-left: 28px;
}
::v-deep ol#carousel-1___BV_indicators_ {
  display: none;
}
div#slider {
  padding-left: 23px;
  padding-right: 23px;
}
::v-deep .card-title {
  margin-bottom: 0.75rem;
  color: #000;
  font-weight: 700;
  font-size: 15pt;
  text-align: left;
}
::v-deep .btn {
  background-color: #183167;
  border-color: white;
  font-size: 1rem;
  border-radius: 0;
  width: 50%;
}
.pad {
  padding: 0px 50px;
}
@media only screen and (min-width: 769px) {
  .hideOnDesk {
    display: none;
  }
}

@media only screen and (max-width: 990px) {
  ::v-deep #sliderTitle {
    display: none;
  }
  .pad {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnmob {
    display: none;
  }
  .hideOnDesk > .card-header-pills {
    padding-right: 0%;
    display: grid;
  }
}

@media only screen and (max-width: 500px) {
  ::v-deep th.venueOption {
    display: none;
  }
  ::v-deep td.venueOption {
    display: none;
  }
  ::v-deep .btn {
    background-color: #183167;
    border-color: white;
    font-size: 1rem;
    border-radius: 0;
    width: 100%;
  }
  ::v-deep .carousel-inner {
    padding-bottom: 0em;
  }
  ::v-deep .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  ::v-deep .card-title {
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
