<template>
  <div>
    <div class="contact container">
      <b-row class="contactForm">
        <b-col class="address" md="6" cols="12">
          <h2 class="getintouch">
            Get In Touch
          </h2>
          <div class="our-detail-Section">
            <p class="contact-address">
              {{ contact.acf.line_one_address }}
            </p>
            <p class="contact-address">
              {{ contact.acf.line_two_address }}
            </p>
            <p class="contact-address">
              {{ contact.acf.line_three_address }}
            </p>
            <p class="contact-address">
              {{ contact.acf.postcode }}
            </p>
            <p class="contact-address">
              <a class="mailto" href="mailto:contact.acf.email">{{
                contact.acf.email
              }}</a>
            </p>
          </div>
        </b-col>
        <b-col md="6" cols="12" class="formWidth">
          <Form :config="config" />
        </b-col>
      </b-row>
    </div>
    <div>
      <Footer :config="config" :white="white" />
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Form from "@/components/form.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "contact",
  props: ["config"],
  components: { Form, Footer },
  data() {
    return {
      contact: [],
      contactF: true,
      white: false,
    };
  },
  created() {
    const json = "wp/v2/pages/15";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.contact = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
a.mailto {
  color: #183167;
}
::v-deep .row.FooterSponsorsSup {
  margin: auto;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 5%;
  margin-bottom: 5%;
}
p.contact-address {
  margin-bottom: 0.5em;
  font-size: 15pt;
}
.our-detail-Section {
  margin-top: 1em;
  color: #ffffff;
}
h2.getintouch {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.7rem;
}
.contact {
  padding-top: 5%;
  padding-left: 0%;
  padding-right: 2%;
  padding-bottom: 5%;
  margin-top: -43%;
  color: white;
}
.homeSplash {
  margin-top: -206px;
  width: 100%;
}
::v-deep .sub {
  color: white;
  background-color: #183167;
}
.address {
  padding-top: 4%;
  padding-left: 4%;
}
::v-deep input {
  width: 100%;
  border: 1px solid #000;
  border-radius: 0px;
}
::v-deep .form-control {
  border-radius: 0px !important;
}
@media only screen and (max-width: 1440px) {
  ::v-deep textarea {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 990px) {
  .contactForm {
    display: block;
  }
  .formWidth {
    margin-top: 11em;
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .homeSplash {
    margin-top: -144px;
    width: 100%;
  }
  .formWidth {
    margin-top: 3em;
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
  .nav-link {
    color: white;
    font-size: 20pt;
    line-height: 2rem;
    font-weight: 100;
    text-align: center;
    width: 250px;
    margin-left: -1.5em;
  }
}
@media only screen and (max-width: 550px) {
  .homeSplash {
    margin-top: -143px !important;
    width: auto;
    height: 400px !important;
  }
  .address {
    padding-top: 0;
    padding-left: 10%;
    margin-top: -120px;
  }
  .formWidth[data-v-2d05c2f6] {
    margin-top: 4em;
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  ::v-deep .row.FooterSponsorsSup {
    margin: auto;
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 10%;
    margin-bottom: 0%;
  }
  ::v-deep textarea {
    width: 100%;
  }
  ::v-deep input {
    width: 100%;
    border: 1px solid #000;
    border-radius: 0px;
  }
  ::v-deep .blue {
    margin-left: 20px;
  }
  ::v-deep .contactformsub {
    width: 100%;
    margin-top: 30px;
    background-color: #183167;
    font-size: 1.1rem;
    border-radius: 0px;
  }
  ::v-deep .form-control {
    border: 1px solid #000;
  }
}
</style>
