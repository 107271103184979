<template>
  <b-card-body class="text-center table-responsive">
    <b-table :items="data" :fields="fields" >
      <template #cell(reports)="row">
        <b-nav card-header pills>
          <template v-if="row.item.reports !==''">
            <b-dropdown
              id="dropdown-dropleft"
              dropleft
              v-if="Array.isArray(row.item.reports.reports_entry)"
              text="Report"
            >
              <b-dropdown-item
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: row.item.code, fTour: true, title: reports.report_title  }
                }"
                v-for="(reports, index) in row.item.reports.reports_entry"
                :key="index"
                :title="reports.report_title"
                v-show="reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'"
                >{{ reports.report_title }}</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown id="dropdown-dropleft" dropleft v-else text="Report">
              <b-dropdown-item
                :to="{
                  name: 'reports',
                  query: { url: reports.report_url, id: id, code: row.item.code, fTour: true, title: reports.report_title  }
                }"
                v-for="(reports, index) in row.item.reports"
                :key="index"
                :title="reports.report_title"
                v-show="reports.report_title != 'Live Scoring Monitor' && reports.report_title != 'Scoring Statistics' && reports.report_title != 'Course Statistics'"
                >{{ reports.report_title }}</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-nav>
      </template>
      <template #cell(full_name)="row">
        <b-nav-item :to="'/tournament/' + row.item.code + '/' + id" :class="{major : row.item.major == 'Y'}">
          {{ row.item.full_name }}
        </b-nav-item>
      </template>
      <template #cell(start_date)="row">
        <div v-if="code == row.item.code" class="activeTournament"></div>
        <span :class="{major : row.item.major == 'Y'}">
          {{ row.item.start_date }}
        </span>
      </template>
      <template #cell(course)="row">
        <span :class="{major : row.item.major == 'Y'}">
          {{ row.item.course }}
        </span>
      </template>
      <template #cell(total_prize_fund)="row">
        <span :class="{major : row.item.major == 'Y'}">
          {{ row.item.total_prize_fund }}
        </span>
      </template>
    </b-table>
  </b-card-body>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "fields", "data", 'code'],
  filters: {
    base: function(value) {
      return btoa(value);
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.code === item.code) return 'live'
    }
  }
};
</script>

<style scoped>
::v-deep .dropleft .dropdown-toggle::before {
  display: none;
}
.activeTournament {
  height: 67px;
  background: #668CE0;
  position: absolute;
  left: 70px;
  margin-top: -22px;
  width: 5px;
}
.major {
  color: #3E3E3E!important;
  font-weight: bold!important;
}
.major > a {
  color: #3E3E3E!important;
  font-weight: bold!important;
}
li{
  list-style: none;
}
.nav-link {
  color: #222629;
}
::v-deep th {
  position: sticky;
  top: 0;
  background-color: #263056;
  z-index: 111;
}

::v-deep .live{
  color: white;
  background-color: #3E3E3E !important;
}
::v-deep .live>td>li>a{
  color: white;
}

::v-deep .live>td>ul>div>button{
  border: 0;
}
@media only screen and (max-width: 990px) {
  .activeTournament {
    height: 67px;
    background: #ec0c1e;
    position: absolute;
    left: 19px;
    margin-top: -22px;
    width: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .activeTournament {
    display: none;
  }
  td.scheduleNav > li > a {
    font-size: 9pt;
  }
  ::v-deep td.scheduleNav {
    font-size: 9pt!important;
  }
  ::v-deep .btn {
    background-color: #183167;
    border-color: white;
    font-size: 9pt;
    border-radius: 0;
    width: 100%;
  }
}
</style>
