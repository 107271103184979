<template>
    <b-container>
        <template v-if="wpInfo[0].better_featured_image == null">

        </template>
        <template v-else>
            <div>
                <img class="newImage" :src="wpInfo[0].better_featured_image.source_url">
            </div>
        </template>
        <div class="titleSection">
            <h1 data-v-0aef1de3="" class="FullScheduleTitle" v-html="wpInfo[0].title.rendered"></h1>
        </div>
        <div class="contentSection">
            <p v-html="wpInfo[0].content.rendered"></p>
        </div>
    </b-container>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:"single",
    props: ["config"],
    components: {
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: ''
        }
    },
    metaInfo() {
        return {
            title: this.wpInfoTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    created() {
    const json = "wp/v2/pages?slug=" + this.$route.name + "&randomadd=" + new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpInfo = data;
        console.log("this.wpInfo -- ")
        console.log(this.wpInfo)
        this.wpInfoTitle = data[0].title.rendered;
        console.log("this.wpInfoTitle")
        console.log(this.wpInfoTitle)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
}
</script>

<style scoped>
::v-deep .newImage {
    width: 100%!important;
    margin-top: 50px;
}
::v-deep div > strong {
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
}
::v-deep h2, .h2 {
    font-size: 1.8rem;
    color: #668CE0;
    font-weight: bolder;
}
/* ::v-deep h3 {
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
} */
.FullScheduleTitle {
    font-weight: 700;
}
.titleSection {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contentSection {
    margin-top: 50px;
    margin-bottom: 50px;
}
::v-deep h3 {
    font-weight: bold;
}
</style>